import axios from 'axios'
import { stringify } from 'qs'

// import fetch from 'isomorphic-fetch'
// import { getCookie } from './utils'

// Polyfill Promises for IE and older browsers.
require('es6-promise').polyfill()

/* eslint-disable */
export const baseURL                     = window.__REACT_DESKTOP_LAST_UPDATES__BASE_URL__ || '/'
export const novelLastUpdatesListURL     = window.__REACT_DESKTOP_LAST_UPDATES__CHAPTERS_LIST_URL__ || 'http://localhost:8001/novel-last-updates/api/list-novels/'
export const lastUpdateNovelsPageURL     = window.__REACT_DESKTOP_LAST_UPDATES__PAGE_URL__ || 'http://localhost:8000/truyen-moi-cap-nhat/'
// export const apiGoToChapter              = window.__REACT_DESKTOP_LAST_UPDATES__GO_TO_CHAPTER___ || 'http://localhost:8000/novel-chapters/api/go-to-chapter/'
/* eslint-enable */

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

export function fetchLastUpdateNovels (params) {
  let url = `${novelLastUpdatesListURL}?${stringify(params)}`
  return axios.get(url)
}

// export function goToChapters (params) {
//   return axios.get(`${apiGoToChapter}?novel_id=${novelId}&${stringify(params)}`)
// }
