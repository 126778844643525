import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import LastUpdates from './components/LastUpdates'

import './App.scss'
import { baseURL } from './makeAndHandleRequest'

const NovelComment = () => (
  <BrowserRouter basename={baseURL}>
    <Switch>
      <Route path='/' exact component={LastUpdates} />
      {/* <Route path='/edit/:objectId/' exact component={ChapterDetail} /> */}
    </Switch>
  </BrowserRouter>
)

export default NovelComment
