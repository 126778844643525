import React, { Component, Fragment } from 'react'
import { fetchLastUpdateNovels, lastUpdateNovelsPageURL } from '../makeAndHandleRequest'
import moment from 'moment'
import 'moment/locale/vi'
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { LastUpdatesLoader, LastUpdatesMobleLoader } from '../Loaders'
import NumberFormat from 'react-number-format'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { viStrings } from '../utils'
import ReactTooltip from 'react-tooltip'
import Popover from 'react-awesome-popover'
import VisibilitySensor from 'react-visibility-sensor'
import { enquireScreen } from 'enquire-js'
// import './LastUpdates.css'
// import './LastUpdates.css'

moment.locale('vi')
const formatter = buildFormatter(viStrings)
const dateFormat = 'h:mm Do MMMM YYYY'

class LastUpdates extends Component {
  state = {
    isMobile: null,
    items: null,
    cSelected: [],
    shouldBeShown: false
  }

  componentDidMount () {
    enquireScreen(mobile => {
      this.setState({
        isMobile: mobile
      })
    })
  }

  loadData = () => {
    fetchLastUpdateNovels().then(res => {
      this.setState({
        items: res.data
      })
      ReactTooltip.rebuild()
    })
  }

  handleOnVisible = (isVisible) => {
    if (!this.state.shouldBeShown && isVisible) {
      this.loadData()
    }
    if (isVisible) {
      this.setState({
        shouldBeShown: true
      })
    }
  }

  render () {
    const { items, isMobile } = this.state

    return (
      <VisibilitySensor onChange={(isVisible) => this.handleOnVisible(isVisible)}>
        <div className='card card-home lastUpdatesReact'>
          <div className='card-header d-flex align-items-center justify-content-between'>
            <h2 className='h6' style={{ marginBottom: 0 }}><a href={lastUpdateNovelsPageURL} className='text-dark text-decoration-none' title='Truyện Sáng Tác Mới Cập Nhật'>Truyện Sáng Tác Mới Cập Nhật</a></h2>
            <a href={lastUpdateNovelsPageURL} className='card-more' title='Truyện Sáng Tác Mới Cập Nhật' aria-label='Truyện Sáng Tác Mới Cập Nhật'>
              <i className='fas fa-long-arrow-alt-right' /><span style={{ display: 'none' }}>Truyện Sáng Tác Mới Cập Nhật</span>
            </a>
          </div>
          {isMobile && (
            <>
              {!items && (
                <Fragment>
                  <LastUpdatesMobleLoader />
                  <LastUpdatesMobleLoader />
                  <LastUpdatesMobleLoader />
                  <LastUpdatesMobleLoader />
                  <LastUpdatesMobleLoader />
                </Fragment>
              )}
              {items && (
                <table className='table table-sm table-striped'>
                  <tbody>
                    {items.map(item => (
                      <tr key={item.id}>
                        <td className='genre'><a href={item.genre.url}>{item.genre.name}</a></td>
                        <td>
                          <div style={{ fontSize: 16, fontWeight: '700' }}><a href={item.url}>{item.name}</a></div>
                          {item.lastChapter && (
                            <div style={{ margin: '4px 0' }}><a href={item.lastChapter.url} style={{ fontSize: 14, color: '#056bf1' }}>Chương {item.lastChapter.chapNum}: {item.lastChapter.chapName}</a></div>
                          )}
                          <div>{item.author}</div>
                        </td>
                        <td style={{ width: 140 }}>
                          <TimeAgo date={item.updatedAt} formatter={formatter} data-tip={moment(item.updatedAt).format(dateFormat)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
          {!isMobile && (
            <>
              {!items && (
                <Fragment>
                  <LastUpdatesLoader />
                  <LastUpdatesLoader />
                  <LastUpdatesLoader />
                  <LastUpdatesLoader />
                  <LastUpdatesLoader />
                </Fragment>
              )}
              {items && (
                <table className='table table-sm table-striped'>
                  <tbody>
                    {items.map(item => (
                      <tr key={item.id}>
                        <td className='genre'><a href={item.genre.url}>{item.genre.name}</a></td>
                        <td className='novel-name'>
                          <Popover placement='top' action='hover' arrow={false} contentClass='rap-popover-content'>
                            <div style={{ maxWidth: '15vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <a href={item.url}>{item.name}</a>
                            </div>
                            <div className='pad'>
                              <div className='d-flex'>
                                <LazyLoadImage
                                  alt=''
                                  height={90}
                                  width={60}
                                  src={item.coverUrl}
                                  style={{ flexBasis: '60px' }}
                                />
                                <div className='ml-2' style={{ flex: 1, width: '100%' }}>
                                  <div className='h6' style={{ whiteSpace: 'normal' }}>{item.name}</div>
                                  <div>{item.author}</div>
                                  <div>
                                    <i className='far fa-eye' />
                                    <span className='mr-2'> <NumberFormat value={item.views} displayType={'text'} thousandSeparator /></span>
                                    <i className='far fa-thumbs-up' />
                                    <span> <NumberFormat value={item.likes} displayType={'text'} thousandSeparator /></span>
                                  </div>
                                  <div>
                                    {item.genres.length > 0 && item.genres.map(genreItem => (
                                      <span className='badge badge-light mr-1' key={genreItem}>{genreItem}</span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover>
                        </td>
                        <td>
                          {item.lastChapter && (
                            <>
                              <Popover placement='top' action='hover' arrow={false} contentClass='rap-popover-content'>
                                {item.lastChapter.chapName ? (
                                  <div style={{ maxWidth: '25vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <a href={item.lastChapter.url} title={`Chương ${item.lastChapter.chapNum}: ${item.lastChapter.chapName}`}>Chương {item.lastChapter.chapNum}: {item.lastChapter.chapName}</a>
                                  </div>
                                ) : (
                                  <Fragment>
                                    <a href={item.lastChapter.url} title={`Chương ${item.lastChapter.chapNum}`}>Chương {item.lastChapter.chapNum}</a>
                                  </Fragment>
                                )}
                                <div className='pad'>
                                  <div className='iconText'>
                                    <i className='far fa-file-word' />
                                    <span className='ml-2'><NumberFormat value={item.lastChapter.wordCount} displayType={'text'} thousandSeparator /></span>
                                  </div>
                                  <div className='iconText'>
                                    <i className='fas fa-pen-nib' />
                                    <span className='ml-2'>{item.author}</span>
                                  </div>
                                  <div className='iconText'>
                                    <i className='far fa-clock' />
                                    <span className='ml-2'>{moment(item.lastChapter.createdAt).format(dateFormat)}</span>
                                  </div>
                                </div>
                              </Popover>
                            </>
                          )}
                        </td>
                        <td style={{ whiteSpace: 'nowrap' }}><a href={item.authorUrl}>{item.author}</a></td>
                        <td style={{ whiteSpace: 'nowrap', paddingRight: 16 }}>
                          <div style={{ float: 'right' }}>
                            <TimeAgo date={item.updatedAt} formatter={formatter} data-tip={moment(item.updatedAt).format(dateFormat)} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {items && items.length === 0 && (
                <div className='px-3 py-2'>
                  <em>Không có truyện nào trong danh sách.</em>
                </div>
              )}
            </>
          )}
          <ReactTooltip />
        </div>
      </VisibilitySensor>
    )
  }
}

export default LastUpdates
