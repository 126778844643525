import React from 'react'
import ContentLoader from 'react-content-loader'

export const ListingLoader = props => (
  <ContentLoader
    width={900}
    height={170}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <circle cx='20' cy='20' r='20' />
    <rect x='50' y='5' rx='4' ry='4' width='100' height='10' />
    <rect x='50' y='25' rx='4' ry='4' width='80' height='10' />

    <rect x='50' y='50' rx='4' ry='4' width='600' height='30' />
    <rect x='50' y='100' rx='4' ry='4' width='400' height='30' />
    <rect x='50' y='150' rx='4' ry='4' width='850' height='1' />
  </ContentLoader>
)

export const LastUpdatesLoader = props => (
  <ContentLoader
    width={1000}
    height={40}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    ariaLabel={null}
    {...props}
  >
    <rect x='0' y='0' rx='2' ry='2' width='100' height='20' />
    <rect x='110' y='0' rx='2' ry='2' width='240' height='20' />
    <rect x='360' y='0' rx='2' ry='2' width='320' height='20' />
    <rect x='690' y='0' rx='2' ry='2' width='200' height='20' />
    <rect x='900' y='0' rx='2' ry='2' width='100' height='20' />
  </ContentLoader>
)

export const LastUpdatesMobleLoader = props => (
  <ContentLoader
    width={1000}
    height={40}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    ariaLabel={null}
    {...props}
  >
    <rect x='0' y='0' rx='2' ry='2' width='340' height='20' />
    <rect x='360' y='0' rx='2' ry='2' width='320' height='20' />
    <rect x='690' y='0' rx='2' ry='2' width='300' height='20' />
  </ContentLoader>
)
