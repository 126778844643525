// import React from 'react'
import type { L10nsStrings } from 'react-timeago/lib/formatters/buildFormatter'
import numeral from 'numeral'

export const formatNum = (number, useDashZero = true) => {
  const n = numeral(number)
  if (n.value() === 0 && useDashZero) {
    return '-'
  } else if (n.value() === 0) {
    return '0'
  }
  const x = n.format('0.0a')
  return x.endsWith('.0') ? n.format('0a') : x
}

export function getCookie (name) {
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim()
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

// Logic xử lý thêm hoặc xóa param trên url
export function filterParameterURL (paramListRemove, paramListAdd) {
  /*
  * paramListRemove = ['page', 'sort_by', ...]
  * paramListAdd = [{key: 'page', value: 1}, ...]
  * */
  let hash = window.location.hash
  // Xóa hash
  let windowLocation = handleRemoveHash(hash)
  const { _url } = windowLocation
  let url = _url

  for (let param of paramListRemove) {
    url = removeURLParameter(url, param)
  }
  for (let param of paramListAdd) {
    url = addURLParameter(url, param.key, param.value)
  }
  window.history.replaceState(null, null, url)
}

export function addURLParameter (url, parameter, value) {
  let loc = url
  loc += loc.indexOf('?') === -1 ? '?' : '&'
  return loc + parameter + '=' + value
}

export function removeURLParameter (url, parameter) {
  let urlparts = url.split('?')
  if (urlparts.length >= 2) {
    let prefix = encodeURIComponent(parameter) + '='
    let pars = urlparts[1].split(/[&;]/g)

    for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }
    url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
    return url
  } else {
    return url
  }
}

export function handleRemoveHash (hash) {
  let url = window.location.href
  let search = document.location.search
  if (hash.length > 0) {
    let params = hash.split('?')
    if (params.length >= 2) {
      params.splice(0, 1)
      url = url.split('#')[0] + '?' + params.join('')
      search = '?' + params.join('')
    }
  }
  return { _url: url.split('#')[0], _search: search }
  // return url.split('#')[0]
}

export const viStrings: L10nsStrings = {
  prefixAgo: '',
  prefixFromNow: null,
  suffixAgo: null,
  suffixFromNow: 'trước',
  seconds: 'chưa đến 1 phút',
  minute: '1 phút',
  minutes: '%d phút',
  hour: '1 tiếng',
  hours: '%d tiếng',
  day: '1 ngày',
  days: '%d ngày',
  month: '1 tháng',
  months: '%d tháng',
  year: '1 năm',
  years: '%d năm',
  wordSeparator: ' '
}
